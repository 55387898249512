/* Centering the specific total persons card */
.custom-row {
    display: flex;
    justify-content: center;
}

.custom-col {
    display: flex;
    justify-content: center;
}

.custom-card {
    width: 100%; /* Ensure it takes full width of the column */
}

.custom-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; /* Ensures the text is centered */
}
