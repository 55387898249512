/* Global reset and body styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #f4f5f7; /* Light gray background for clean look */
    font-family: 'Arial', sans-serif; /* Simplified and modern font */
    color: #333; /* Slightly lighter text color for better readability */
}

/* Navbar Styling */
.navbar {
    background-color: #343a40; /* Dark background for navbar */
    padding: 0.8rem 1rem; /* Spacing for navbar */
}

.navbar-brand {
    font-size: 1.4rem; /* Slightly larger font for brand */
    color: #fff;
    font-weight: bold;
}

.navbar-brand span {
    text-transform: uppercase;
}

/* Sidebar */
.sidebar {
    background-color: #212529; /* Darker background for sidebar */
    width: 250px;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
}

.sidebar .nav-link {
    color: #adb5bd; /* Lighter text color for nav items */
    padding: 0.8rem 1.2rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.sidebar .nav-link:hover {
    background-color: #007bff; /* Highlight on hover */
    color: #fff;
}

.sidebar .nav-link.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    border-left: 4px solid #fff; /* Indicator for active links */
}

.sidebar i {
    margin-right: 10px;
}

/* Main Content */
.content-wrapper {
    margin-left: 250px; /* Ensure content is properly aligned */
    padding: 2rem;
    background-color: #fff;
    min-height: 100vh;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}


footer span {
    color: #686868;
}

/* Buttons */
.btn-primary {
    background-color: #007bff; /* Primary color for buttons */
    border: none;
    color: white;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

table th, table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
}

table th {
    background-color: #f8f9fa;
    color: #333;
}

table td {
    background-color: #fff;
    color: #000000;
}

/* Input Fields */
input, select, button {
    font-size: 1rem;
    border-radius: 5px;
}

p {
    color: #000000;
}

input:focus, select:focus, button:focus {
    outline: none;
    border-color: #007bff;
}

.react-datepicker__input-container input {
    border-radius: 5px;
    padding: 0.8rem;
    width: 100%;
}

/* Links */
a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Pagination */
.page-link {
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding: 0.5rem 1rem;
}

.page-link:hover {
    background-color: #007bff;
    color: #fff;
}


/* Ensure text color is black inside form-control */
.form-control {
    color: #000 !important; /* Force text color to black */
}

.form-label {
    color: #000 !important; /* Force text color to black */
}

.form-select {
     color: #000 !important; /* Force text color to black */
}


/* On focus, change placeholder color */
.form-control:focus::placeholder {
    color: #007bff; /* Blue color on focus for placeholders */
}

/* On focus, ensure text color remains black */
.form-control:focus {
    color: #000 !important; /* Ensure text color remains black on focus */
    border-color: #007bff; /* Blue border on focus */
}

.sidebar-brand-text {
    font-family: Arial, sans-serif; /* Set font to Arial */
    font-weight: bold; /* Make the text bold if desired */
    color: #fff; /* Ensure the text color is white for visibility */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 80px;
    }

    .content-wrapper {
        margin-left: 80px;
    }

    .sidebar .nav-link {
        padding: 0.75rem;
        font-size: 0.9rem;
    }

    .sidebar .navbar-brand span {
        display: none;
    }

    .sidebar .nav-link i {
        font-size: 1.5rem;
    }

    .spinner-border {
        width: 2rem;
        height: 2rem;
    }
}


/* Hide the printable section by default */
.printable-section {
    display: none;
}

/* Display the printable section only when printing */
@media print {
    .printable-section {
        display: block;
    }
}