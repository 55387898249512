/* Account Information Card Styling */
.account-info-card .card-body {
    padding: 2rem;
    text-align: center; /* Centers text and button */
}

.account-info-card .profile-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.account-info-card .fa-user-circle {
    font-size: 4rem; /* Larger icon for profile */
    color: #007bff; /* Primary color */
}

.account-info-card .text-dark {
    color: #333; /* Darker text for contrast */
}

.account-info-card .text-muted {
    color: #6c757d; /* Lighter gray for secondary text */
}

.account-info-card .ms-3 {
    margin-left: 1rem; /* Spacing between icon and text */
}

.account-info-card button {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.account-info-card button:hover {
    background-color: #0056b3;
    color: #fff;
}
