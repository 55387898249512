/* Loader wrapper to center the loader */
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Fixed position to ensure it's always centered */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
    z-index: 9999; /* Ensures it's above other content */
}

/* Styling for the loader itself */
.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db; /* Blue color for the spinning top */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite; /* Smooth spinning animation */
}

/* Keyframes for the spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Optional: Adding a subtle glow around the loader for better visibility */
.loader {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Soft shadow for the loader */
}

/* Accessibility enhancement: Announcing the loader */
.loader-wrapper[aria-live="assertive"] {
    visibility: visible;
    animation: fadeIn 0.3s ease-in-out;
}

/* Fade-in animation to make the loader appear smoothly */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
